import React from 'react'
import { Link } from 'react-router-dom'

// Custom CSS import
import './hairCare.css'

// Custom componenets import
import Base from '../base/Base'
import Counter from '../counter/Counter'
import CustomerReviews from '../customer-reviews/CustomerReviews'

const HairCare = () => {
    return (
        <>
            <Base title='Hair-Care' imageLocation='./images/hair-care/hair-care-banner.png' >
                <div className="hair-care-container mt-5">
                    {/* left side contents of thepage */}
                    <div className="left-side-contents ">
                        <div className="heade-image">
                            <img className='bottom-page-galery-image' src="./images/hair-care/women-with-hair-services.jpg" alt="loading" />
                        </div>
                        <p>
                        An important part of maintaining healthy hair is regular trims, which are important for achieving the look you want. At Matrix Unisex Salon, we offer wonderful haircare services using the most advanced hair care products available on the market today. Our talented staff will help you achieve your hairstyle goals, whether it's a new haircut or color treatment. Call today to schedule an appointment.
                        </p>

                        <p>
                        We are a top choice for personal and professional haircare services. Our stylists are dedicated to integrating the latest techniques with your natural style to deliver stunning results, every time. We're here to make you look good. Whether you need your hair cut, styled or coloured, we'll help you find exactly what you're looking for.
                        </p>

                        <p>
                        Pamper yourself - come in and let our talented stylists treat your hair with the care it deserves.I’m here to help you feeling beautiful. I provide haircuts and styling services for men, women and kids. 
                        </p>

                        <ul className="bullet-items">
                            <li>All Types of Women Hair Cut and Style</li>
                            <li>All Types of Kids's Hair Cut and Style.</li>
                            <li>All Types of Men's Hair Cut and Style</li>
                            <li>Blow Dry.</li>
                            <li>Party Hair Style.</li>
                        </ul>
                        <div className="page-bottom-gallery">
                            <div className="left-side-image">
                                <img className='bottom-page-galery-image' src="./images/hair-care/hair-cutting.jpg" alt="loading" style={{ borderRadius: "1rem", border: "1px solid", borderColor: "white" }} />
                            </div>
                            <div className="right-side-image">
                                <img className='bottom-page-galery-image' src="./images/hair-care/final-hair-cut.jpg" alt="loading" style={{ borderRadius: "1rem", border: "1px solid", borderColor: "white" }} />
                            </div>
                        </div>
                    </div>

                    {/* ********************************** right side contents of the page ************************ */}
                    <div className="right-side-contents">
                        {/* Hair color category */}
                        <div className="category">
                            <h2 className='text-center text-white' >Hair Color</h2>
                            <ul>
                                <li className='side-bar-list-item'><Link to="#"> Highlights</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Global hair color</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Balayage color</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Ombre color</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Color melting</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Foliage color</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Babylight color</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Sun Shade color</Link></li>
                            </ul>
                        </div>
                        {/* hair cut category */}
                        <div className="category">
                            <h2 className='text-center text-white' >Hair Cut</h2>
                            <ul>
                                <li className='side-bar-list-item'><Link to="#"> Male hair cut</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Women hair cut</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Child hair cut</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Short hair cut</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Change style</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Split end cut</Link></li>
                            </ul>
                        </div>
                        {/* Hair spa category */}
                        <div className="category">
                            <h2 className='text-center text-white' >Hair Spa</h2>
                            <ul>
                                <li className='side-bar-list-item'><Link to="#"> Baiolage care</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Special care</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Advance care</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Moraccan hair spa</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Keratin hair spa</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Dandruff treatment</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Hairfall treatment</Link></li>
                            </ul>
                        </div>
                        {/* Hair Straightening category */}
                        <div className="category">
                            <h2 className='text-center text-white' >Hair Straightning</h2>
                            <ul>
                                <li className='side-bar-list-item'><Link to="#"> Hair smoothing</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Hair straightening</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Hair rebounding</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Keratin</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Hair bootox</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Hair collagen</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> K18 treatment</Link></li>
                            </ul>
                        </div>
                        {/* Hair Styling category */}
                        <div className="category">
                            <h2 className='text-center text-white' > Hair Styling</h2>
                            <ul>
                                <li className='side-bar-list-item'><Link to="#"> Blowdry set</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Iron set</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Tong curl set</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Out curl set</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Wave set</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Spiral curl set</Link></li>
                            </ul>
                        </div>
                    </div>


                </div>
                {/* page section counter */}
                <Counter />

                {/* page section Customer Reviews */}
                <CustomerReviews />
            </Base>
        </>
    )
}

export default HairCare