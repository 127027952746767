import React from 'react'

// Custom CSS import
import './welcomeSection.css'

const WelcomeSection = () => {
    return (
        <>
            <div className="container mt-5 welcome-section-container">
                <div className="welcome-left-side-contents">
                    <div className="image-container">
                        <img src="./images/home-page/welcome.jpg" alt="loading..." />
                        <div className="image-tag text-center">
                            <h2 >15+</h2>
                            <p>years <br />experience</p>
                        </div>
                    </div>
                </div>
                <div className="welcome-right-side-contents">
                    <h2 className='section-heading' style={{ color: "#D9DDDC" }} >Welcome to <br />Matrix Unisex Salon</h2>
                    <hr style={{ opacity: "1", color: "#ffbb33", maxWidth: "150px", marginBottom: "3rem" }} />
                    <h4 className="section-sub-heading my-4" style={{ color: "#BEBDB8" }}>Most Trusted salon in Bhopal loved by thousands of people</h4>
                    <p className="section-contents">We know what trends are hottest, no matter what season it is. We also understand that different colors suit various skin tones and hair types, so we have a range of colors for you to choose from including darker shades with rich undertones.
                        <br />
                        No more waiting hours for your appointment or having to book two visits - come to Matrix Unisex Salon and get it all done under one roof!</p>
                    <a href="/contact" className="btn btn-outline btn-outline-primary mt-4">Know More</a>
                </div>
            </div>
        </>
    )
}

export default WelcomeSection