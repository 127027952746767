import React from 'react'
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";

// Custom components import
import HomePage from './core/home-page/HomePage';
import Navbar from './core/navbar/Navbar';
import TopNavbar from './core/top-navbar/TopNavbar';
import AboutPage from './core/about-page/AboutPage';
import HairCare from './core/hair-care/HairCare';
import HairColor from './core/hair-color/HairColor';
import HairStyling from './core/hair-styling/HairStyling';
import SkinCare from './core/skin-care/SkinCare';
import Bridal from './core/bridal/Bridal';
import Treatments from './core/treatments/Treatments';
import Gallery from './core/gallery/Gallery';
import ContactPage from './core/contact-page/ContactPage';
import SalonLocation from './core/salon-location/SalonLocation';
import BodyCare from './core/bodyCare/BodyCare';


const ProjectRoutes = () => {
    return (
        <>
            <Router>
                <TopNavbar />
                <Navbar />
                <Routes>
                    <Route exact path="/" element={<HomePage />} />
                    <Route exact path="/about" element={<AboutPage />} />
                    <Route exact path="/hair-care" element={<HairCare />} />
                    <Route exact path="/hair-color" element={<HairColor />} />
                    <Route exact path="/hair-styling" element={<HairStyling />} />
                    <Route exact path="/skin-care" element={<SkinCare />} />
                    <Route exact path="/bridal" element={<Bridal />} />
                    <Route exact path="/treatments" element={<Treatments />} />
                    <Route exact path="/gallery" element={<Gallery />} />
                    <Route exact path="/contact" element={<ContactPage />} />
                    <Route exact path="/salon-locations" element={<SalonLocation />} />
                    <Route exact path="/body-care" element={<BodyCare />} />
                </Routes>
            </Router>
        </>
    )
}

export default ProjectRoutes;