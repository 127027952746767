import React from 'react'
import SocialMedia from '../socialMedia/SocialMedia'

// Custom CSS import
import './topNavbar.css'

const TopNavbar = () => {
    return (
        <>
            <div className="top-container container-fluid ">
                <div className="items-container container">
                    <div className="timing-contact d-flex text-white">
                        <h6>Open all days 10 am to 08 pm. Helpline No.: </h6>
                        
                        <a className='text-white mx-2' href='tel:+919009011223' >+91-9009011223</a> /
                        <a className='text-white ms-2' href='tel:+919584584102' >+91-9584584102</a>
                    </div>
                    <div className="socialmedia-icons">
                        <SocialMedia />
                    </div>

                </div>
            </div>
        </>
    )
}

export default TopNavbar