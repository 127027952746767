import React from 'react'

// Custom CSS import
import './counter.css'


const Counter = () => {
    return (
        <>
            <div className="counter-container">
                <div id="projectFacts" className="sectionClass">
                    <div className="fullWidth eight columns">
                        <div className="projectFactsWrap ">
                            <div className="item wow fadeInUpBig animated animated" data-number="12" style={{ visibility: "visible" }}>
                                <i className="fa fa-briefcase"></i>
                                <p id="number1" className="number">20+</p>
                                <span></span>
                                <p>Skilled Staffs</p>
                            </div>
                            <div className="item wow fadeInUpBig animated animated" data-number="55" style={{ visibility: "visible" }}>
                                <i className="fa fa-smile-o"></i>
                                <p id="number2" className="number">5000+</p>
                                <span></span>
                                <p>Happy clients</p>
                            </div>
                            <div className="item wow fadeInUpBig animated animated" data-number="359" style={{ visibility: "visible" }}>
                                <i className="fa fa-coffee"></i>
                                <p id="number3" className="number">100+</p>
                                <span></span>
                                <p>Services</p>
                            </div>
                            <div className="item wow fadeInUpBig animated animated" data-number="246" style={{ visibility: "visible" }}>
                                <i className="fa fa-camera"></i>
                                <p id="number4" className="number">16+</p>
                                <span></span>
                                <p>Years of Experience</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Counter