import React from 'react'

// Custom CSS import
import './base.css'

// Custom components import
import Footer from '../footer/Footer'

const Base = (
  { title = "Page Title",
    className = "text-black",
    imageLocation = "./images/about-page/about-bannerpng",
    children }
) => {
  return (
    <>
      <div className="base">
        <div className="Header">
          <img className='img-fluid' src={imageLocation} alt="loading..." style={{ width: "100%" }} />
          <div className="centered" id='heading-centered'>
            <h1 style={{color:"white"}}>{title}</h1>
            <hr style={{opacity:"1", color:"#ffbb33"}} />
          </div>
        </div>
        <div className={className}>{children}</div>
        
        {/* footer section */}
        <Footer />
      </div>
    </>
  )
}

export default Base