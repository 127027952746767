import React from 'react'

// Custom CSS import
import './aboutPage.css'

// Custom components import
import Base from '../base/Base'
import Counter from '../counter/Counter'
import CustomerReviews from '../customer-reviews/CustomerReviews'

const AboutPage = () => {
  return (
    <>
      <Base title='About-Us' imageLocation='./images/about-page/about-banner.jpg' >
        {/* about page contents */}
        <div className="container about-page-container mt-5">
          {/* about page section */}
          <div className="row about-page-contents">
            <div className="col-lg-6">
              <h2 className="section-heading" style={{ color: "#D9DDDC" }}>About us</h2>
              <hr style={{ opacity: "1", color: "#ffbb33", maxWidth: "50px", marginBottom: "3rem" }} />
              <p>At Matrix Unisex Salon, we're all about making you feel great about yourself. We know that the best way to achieve that is with a great haircut and a friendly atmosphere—and we've got both covered. Our goal is to give every client the experience they deserve when it comes to their hair and overall look, as well as their overall self-esteem.</p>
              <p>We believe in offering a wide range of services to all our clients. Our goal is to provide you with the most innovative and high quality services and product that will make you feel beautiful. We also strive to create an environment that is welcoming and comfortable for all of our customers.</p>
              <a href="/contact" className="btn btn-outline-warning my-4">Contact Us</a>
            </div>
            <div className="col-lg-3 image-frame gap-xs-1">
              <img src="/images/about-page/women-services.jpg" alt="loading..." />
            </div>
            <div className="col-lg-3 image-frame">
              <img src="/images/about-page/men-services.jpg" alt="loading..." />
            </div>
          </div>
        </div>

        {/* about page what we do section */}
        <div className="what-we-do-section-container mt-5">
          <div className="left-side-contents">
            <img src="./images/about-page/hair-services-in-salon.jpg" alt="loading" />
          </div>
          <div className="right-side-contents p-3">
            <h2 className="section-heading" style={{ color: "#D9DDDC" }}>What We Do</h2>
            <hr style={{ opacity: "1", color: "#ffbb33", maxWidth: "150px", marginBottom: "3rem" }} />
            <p> At Matrix Unisex Salon, we believe that everyone deserves to feel like a princess every day. We want you to come in and leave feeling like the most beautiful version of yourself, no matter what kind of shape you're in or how long you've been wearing a hat.</p>
            <p> hair styling services—whether it's a haircut or color change or just a trim, our stylists have all the tools necessary to make sure that your hair will look great before any event!</p>
            <a href="/contact" className="btn btn-outline-warning my-4">Contact Us</a>
          </div>
        </div>

        {/* counter section of the page */}
        <Counter />

        {/* Customer review section of the page */}
        <CustomerReviews />

      </Base>
    </>
  )
}

export default AboutPage;