import React from 'react'
import Slider from "react-slick";

// Custom CSS import
import './customerReviews.css'

// react external liberary
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const CustomerReviews = () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <div className=" container-fluid text-center customer-review-container mt-5">
                <h2 className='feedback-section-heading' style={{ color: "#D9DDDC" }} >Our Guest Feedback</h2>
                <hr className='our-service-hr' style={{ opacity: "1", color: "#ffbb33", maxWidth: "150px", marginBottom: "3rem" }} />
                <Slider {...settings} className="container my-5">
                    <div>
                        <img src="./images/general-icons/female.png" alt="" className="customer-photo" />
                        <p className="customer-words my-3">It’s awesome hair salon… special thanks to Mr. Jitendra who has given me a complete new look… I would really appreciate their efforts and skills with which he does his work… Its Amazing just loved it </p>
                        <h6 className="customer-name" >Ishika Goyal</h6>
                    </div>
                    <div>
                        <img src="./images/general-icons/female.png" alt="" className="customer-photo" />
                        <p className="customer-words my-3">Very kind and helpful staff, hair dye work was done very neatly and safely. Would definitely recommend Jitendra and Shavan for hairstyling, amazing job done by them!</p>
                        <h6 className="customer-name">Ishanvi</h6>
                    </div>
                    <div>
                        <img src="./images/general-icons/female.png" alt="" className="customer-photo" />
                        <p className="customer-words my-3">Had an awesome experience here!! Got my balayage done by Jitendra, the hairstyle was done exceptionally well by him. The staff was very polite. I would definitely recommend this place for hairstyling and haircut.</p>
                        <h6 className="customer-name">Tanishka Saini</h6>
                    </div>
                    <div>
                        <img src="./images/general-icons/male.png" alt="" className="customer-photo" />
                        <p className="customer-words my-3">Very hygienic and clean salon. Experienced professionals especially Sanjay. I have been coming here for 2 years now and my experience here is damn good.</p>
                        <h6 className="customer-name">Harshwardhan Sharma</h6>
                    </div>
                    <div>
                        <img src="./images/general-icons/female.png" alt="" className="customer-photo" />
                        <p className="customer-words my-3">Had a wonderful experience here! I got highlights for the very first time. Jitendra singh did my haircut and highlights and I absolutely loved it, thank you!
                            The staff is very skilled and polite. Do visit you'll not be disappointed thank you matrix </p>
                        <h6 className="customer-name">Asmita Shrivastava</h6>
                    </div>
                    <div>
                        <img src="./images/general-icons/male.png" alt="" className="customer-photo" />
                        <p className="customer-words my-3">Great Service and well trained Staff. Sanjay Sen is my Permanent Hair Dresser and He knows how to cut hair perfectly.</p>
                        <h6 className="customer-name">Rounak Sonkusre</h6>
                    </div>
                </Slider>
            </div>
        </>
    )
}

export default CustomerReviews;