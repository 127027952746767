import React from 'react'
import { Link } from 'react-router-dom'

// Custom Css import
import './navbar.css'

const Navbar = () => {
    return (
        <>
            <div className="navbar-components sticky-top">
                <nav className="navbar navbar-expand-lg navbar-dark navbar-customization p-lg-1.5">
                    <div className="container">
                        <Link className="navbar-brand" to="/">
                            <img className='navbar-logo' src="./images/logo.png" alt="loading..." />
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavDropdown">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/about">About Us</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to="/services" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Services
                                    </Link>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li><Link className="dropdown-item" to="/hair-care">Hair Care</Link></li>
                                        {/* <li><Link className="dropdown-item" to="/hair-color">Hair Color</Link></li> */}
                                        {/* <li><Link className="dropdown-item" to="/hair-styling">Styling</Link></li> */}
                                        <li><Link className="dropdown-item" to="/skin-care">Skin Care</Link></li>
                                        {/* <li><Link className="dropdown-item" to="treatments">Treatments</Link></li> */}
                                        <li><Link className="dropdown-item" to="body-care">Body Care</Link></li>
                                        <li><Link className="dropdown-item" to="/bridal">Bridal &amp; Grooming</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/salon-locations">Find a Salon</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/gallery">Gallery</Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link className="nav-link" to="/contact">Contact Us</Link>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Navbar