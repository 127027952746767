import React from 'react'
import { Link } from 'react-router-dom'

// Custom CSS import
import './bridal.css'

// custom components import
import Base from '../base/Base'
import Counter from '../counter/Counter'
import CustomerReviews from '../customer-reviews/CustomerReviews'

const Bridal = () => {
    return (
        <>
            <Base title='Bridal &amp; Makeup' imageLocation='./images/bridals/bridal-banner.png' >
                <div className="bridal-makeup-container mt-5">
                    {/* left side contents of thepage */}
                    <div className="left-side-contents ">
                        <div className="heade-image">
                            <img className='bottom-page-galery-image' src="./images/bridals/makeup-main.jpg" alt="loading" />
                        </div>
                        <p>
                            Matrix is the perfect place to get your bridal makeup and grooming needs met. We offer bridal makeup and male grooming services for men, women and both. Our team of professional hair stylists, makeup artists, and make-up technicians are experts when it comes to applying temporary or permanent makeup. They can help you come up with creative ideas for your special day that will surely make you stand out from the rest!
                        </p>

                        <p>
                            We take pride in our work and we always put your satisfaction first. Whether it's a consultation or our final product, you will be satisfied with what we do.
                        </p>

                        <p>
                            <b>Is your wedding coming up?</b> Are you looking for a professional team of beauty experts to help make you look your best on your big day? Look no further, we're here to help. We offer a wide range of beauty services including but not limited to: Bridal Makeup, Male Grooming Services (men's only), Bridal Hair Styling &amp; Updos (women's only).
                        </p>

                        <ul className="bullet-items">
                            <li>Day Party-Makeup Services Available.</li>
                            <li>Night Party-Makeup Services Available.</li>
                            <li>Bridal Makeup Services Available.</li>
                            <li>Groom Makeup Services Available.</li>
                            <li>Bridal and Groom Packages available.</li>
                        </ul>
                        <div className="page-bottom-gallery">
                            <div className="left-side-image">
                                <img className='bottom-page-galery-image' src="./images/bridals/bridal.jpg" alt="loading" style={{ borderRadius: "1rem", border: "1px solid", borderColor: "white" }} />
                            </div>
                            <div className="right-side-image">
                                <img className='bottom-page-galery-image' src="./images/bridals/makeup.jpg" alt="loading" style={{ borderRadius: "1rem", border: "1px solid", borderColor: "white" }} />
                            </div>
                        </div>
                    </div>

                    {/* right side contents of the page */}
                    <div className="right-side-contents">
                        <div className="category">
                            <h2 className='text-center text-white' >Make-up</h2>
                            <ul>
                                <li className='side-bar-list-item'><Link to="#"> Day party make-up</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Night party make-up</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Bridal make-up</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Groom make-up</Link></li>
                            </ul>
                        </div>
                        <div className="category">
                            <h2 className='text-center text-white' >Packages</h2>
                            <ul>
                                <li className='side-bar-list-item'><Link to="#"> Groom package</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Bridal package</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Event package</Link></li>
                            </ul>
                        </div>
                    </div>

                </div>
                {/* page section counter */}
                <Counter />

                {/* page section Customer Reviews */}
                <CustomerReviews />
            </Base>
        </>
    )
}

export default Bridal