import React from 'react'

// Custom CSS import
import './footer.css'

// Custom components import
import SocialMedia from '../socialMedia/SocialMedia'

const Footer = () => {
  return (
    <>
      <div className="footer-contener">
        {/* <!-- Remove the container if you want to extend the Footer to full width. --> */}
        <div className=" mt-5 bg-dark">

          <footer className="text-white text-center text-lg-start bg-dark">
            {/* <!-- Grid container --> */}
            <div className=" container p-4">
              {/* <!--Grid row--> */}
              <div className=" row mt-4">
                {/* <!--Grid column--> */}
                <div className="col-lg-4 col-md-12 mb-4 mb-md-0">
                  <h5 className="text-uppercase mb-4">Matrix Unisex Salon</h5>

                  <p>
                    We have been satisfying our clients with our premium salon services for many years now. And we are still as excited and passionate about what we do as when we started out.
                  </p>

                  <p>
                    No more waiting hours for your appointment or having to book two visits - come to Matrix Unisex Salon and get it all done under one roof!.
                  </p>

                  <div className="mt-4">
                    <SocialMedia />

                    {/* <!-- default social media  --> 
                    <a type="button" className="btn btn-floating btn-light btn-lg"><i className="fab fa-facebook-f"></i></a>
                    <a type="button" className="btn btn-floating btn-light btn-lg"><i className="fab fa-dribbble"></i></a>
                    <a type="button" className="btn btn-floating btn-light btn-lg"><i className="fab fa-twitter"></i></a>
                    <a type="button" className="btn btn-floating btn-light btn-lg"><i className="fab fa-google-plus-g"></i></a>
                    */}
                  </div>
                </div>
                {/* <!--Grid column--> */}

                {/* <!--Grid column--> */}
                <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
                  <h5 className="text-uppercase mb-4 pb-1">Contact Information</h5>

                  {/* <div className="form-outline form-white mb-4">
                    <input type="text" id="formControlLg" className="form-control form-control-lg" />
                    <label className="form-label" for="formControlLg">Search</label>
                  </div> */}

                  <ul className="fa-ul contact-info-column" style={{ marginLeft: "1.65em" }}>
                    <li className="mb-3">
                      <span className="fa-li"><i className="fas fa-home"></i></span><span className="ms-2">Plot No, 20A, Chunabhatti Kolar Rd, next to ICICI Bank, opposite Manoria Hospital, Deepak Society, Chuna Bhatti, Bhopal, Madhya Pradesh 462016</span>
                    </li>
                    <li className="mb-3">
                    <a className='text-white' href='mailto:contact@matrixsalons.in' > <span className="fa-li"><i className="fas fa-envelope"></i></span><span className="ms-2">contact@matrixsalons.in</span></a>
                    </li>
                    <li className="mb-3">
                    <a className='text-white' href='tel:+919009011223' > <span className="fa-li"><i className="fas fa-phone"></i></span><span className="ms-2">+91-9009011223</span></a>
                    </li>
                    <li className="mb-3">
                    <a className='text-white' href='tel:+919584584102' > <span className="fa-li"><i className="fas fa-phone"></i></span><span className="ms-2">+91-9584584102</span></a>
                    </li>
                  </ul>
                </div>
                {/* <!--Grid column--> */}

                {/* <!--Grid column--> */}
                <div className="col-lg-4 col-md-6 mb-4 mb-md-0">
                  <h5 className="text-uppercase mb-4">Opening hours</h5>

                  <table className="table text-center text-white">
                    <tbody className="fw-normal">
                      <tr>
                        <td>Mon - Thu:</td>
                        <td>10am - 08pm</td>
                      </tr>
                      <tr>
                        <td>Fri - Sun:</td>
                        <td>9am - 08pm</td>
                      </tr>
                     
                    </tbody>
                  </table>
                </div>
                {/* <!--Grid column--> */}
              </div>
              {/* <!--Grid row--> */}
            </div>
            {/* <!-- Grid container --> */}

            {/* CALL BUTTON */}
            {/* <div className="call-button">
              <a href="tel:8976031050"> <img src="./images/icons/call-icon.png" alt="" /> </a> */}
              {/* <a href="tel:8976031050"><i className="fas fa-phone-alt fa-3x"></i> </a> */}
            {/* </div> */}

            {/* <!-- Copyright --> */}
            <div className=" text-center p-3 copyright-section" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
              © 2024 Copyright: Matrix Unisex Salon
              
            </div>
            {/* <!-- Copyright --> */}
          </footer>

        </div>
        {/* <!-- End of .container --> */}
      </div >

      {/* CALL BUTTON */}
      <div className="call-button">
        <a href="tel:9009011223"> <img src="./images/icons/call-icon.png" alt="" /> </a>
        <a className='whats'  href="https://wa.link/sid2c8"><i className="fab fa-whatsapp fa-3x"></i> </a> 
      </div>
    </>
  )
}

export default Footer