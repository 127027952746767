import React from 'react';
import ReactDOM from 'react-dom';
import ProjectRoutes from './ProjectRoutes';

// Custom CSS import
import './globalStyle.css'

ReactDOM.render(
  <React.StrictMode>
    <ProjectRoutes />
  </React.StrictMode>,
  document.getElementById('root')
);
