import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { contactPageEnquery } from './corebackendapicalls';

const ContactForm = () => {

    const [values, setValues] = useState({
        fullName: "",
        email: "",
        phone: "",
        message: "",
        error: "",
        success: false
    })

    const { fullName, email, phone, message, error, success } = values;

    const handleChange = name => event => {
        setValues({ ...values, error: false, [name]: event.target.value });
    };

    const onSubmit = event => {
        event.preventDefault(); // prevent the default form even
        setValues({ ...values, error: false }); //set the intial form value as normal form data (... means load the form data)
        contactPageEnquery({ fullName, email, phone, message }) //run the form submit function which takes user value and pass the user value
            .then(data => {
                if (data.error) {
                    setValues({ ...values, error: data.error, success: false });
                } else {
                    // after the form is sumbited again set the form data to the normal state or empty
                    setValues({
                        ...values,
                        fullName: "",
                        email: "",
                        phone: "",
                        error: "",
                        message: "",
                        success: true
                    });
                }
            })
            .catch(console.log("Error in Signup"));
    };

    // custom suceesMessage
    const successMessageAlert = () => {
        return (
            <div className="row">
                <div className="text-center mb-3">
                    <div className="alerr alert-success p-2" style={{ display: success ? "" : "none" }}>
                        Hello  We have received your request and soon will get back to you! <br />
                        you can for any emergency you can also get in touch with us on <br />
                        <h4>+91-9009011223</h4>
                        <Link to="/">Home</Link> page
                    </div>
                </div>
            </div>
        );
    };

    // custom error Message
    const errorMessageAlert = () => {
        return (
            <div className=" text-center mb-3">
                <div className="">
                    <div className="alerr alert-danger p-2" style={{ display: error ? "" : "none" }}> {error}</div>
                </div>
            </div>
        );
    };
    return (
        <>
            {/*actual form input fields  */}
            <div className="form-container p-3 ">
                {/* <h2 className='text-center mb-3'>GET IN TOUCH</h2> */}
                {successMessageAlert()}
                {errorMessageAlert()}
                <form action="" className="form-field text-center">
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="floatingInput" onChange={handleChange("fullName")} placeholder="full name" value={fullName} />
                        <label htmlFor="floatingInput">Full Name</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="number" className="form-control" id="floatingInput" onChange={handleChange("phone")} placeholder="contact number" value={phone} />
                        <label htmlFor="floatingInput">Contact Number</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="email" className="form-control" id="floatingInput" onChange={handleChange("email")} placeholder="name@example.com" value={email} />
                        <label htmlFor="floatingInput">Email address</label>
                    </div>
                    <div className="form-floating mb-3">
                        <textarea className="form-control" onChange={handleChange("message")} placeholder="Leave a comment here" id="floatingTextarea" value={message}></textarea>
                        <label htmlFor="floatingTextarea">Message</label>
                    </div>
                    <button type="submit" onClick={onSubmit} className="btn btn-primary mb-3">Send Message</button>
                </form>
            </div>
        </>
    )
}

export default ContactForm