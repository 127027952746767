import React from 'react'

// Custom CSS import
import './contact.css'

// Custom component import
import ContactForm from '../helper/ContactForm'

const Contact = () => { 
    return (
        <>
            <div className="contact-section">
                <h2 className="text-center" style={{color: "#D9DDDC"}}>Get In Touch</h2>
                <hr className='our-service-hr' style={{ opacity: "1", color: "#ffbb33", maxWidth: "150px", marginBottom: "3rem" }} />
                <div className="contact-section-container container">
                    <div className="left-side-contents">
                        <iframe className='gogle-iframe' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14668.64643859722!2d77.4167013!3d23.2007803!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xec9d169ca410d182!2sMatrix%20unisex%20salon.ME%20Salon!5e0!3m2!1sen!2sin!4v1648493016440!5m2!1sen!2sin" style={{ width: "100%", height: "450 !important" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title='google-map-matrix unisex salon'></iframe>
                    </div>
                    <div className=" contact-us-page-container ">
                        {/*actual form input fields  */}
                        <ContactForm />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact