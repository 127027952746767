import React from 'react'

// Custom css  import
import './gallery.css'

// Custom componenets import
import Base from '../base/Base'
import Counter from '../counter/Counter'
import CustomerReviews from '../customer-reviews/CustomerReviews'

const Gallery = () => {
    return (
        <>
            <Base title='Gallery' imageLocation='./images/gallery/gallery-heading.png' >
                <div className="container gallery-container mt-5 text-center">
                    <div className="row ">
                        <img src="./images/gallery/image-1.jpg" alt="loading..." className="image-frame col-lg-4 col-md-6   " />
                        <img src="./images/gallery/image-2.jpg" alt="loading..." className="image-frame col-lg-4 col-md-6  " />
                        <img src="./images/gallery/image-3.jpg" alt="loading..." className="image-frame col-lg-4 col-md-6  " />
                        <img src="./images/gallery/image-4.jpg" alt="loading..." className="image-frame col-lg-4 col-md-6  " />
                        <img src="./images/gallery/image-5.jpg" alt="loading..." className="image-frame col-lg-4 col-md-6  " />
                        <img src="./images/gallery/image-6.jpg" alt="loading..." className="image-frame col-lg-4 col-md-6  " />
                        <img src="./images/gallery/image-7.jpg" alt="loading..." className="image-frame col-lg-4 col-md-6  " />
                        <img src="./images/gallery/image-8.jpg" alt="loading..." className="image-frame col-lg-4 col-md-6  " />
                        <img src="./images/gallery/image-9.jpg" alt="loading..." className="image-frame col-lg-4 col-md-6  " />
                        <img src="./images/gallery/image-10.jpg" alt="loading..." className="image-frame col-lg-4 col-md-6  " />
                        <img src="./images/gallery/image-11.jpg" alt="loading..." className="image-frame col-lg-4 col-md-6  " />
                        <img src="./images/gallery/image-12.jpg" alt="loading..." className="image-frame col-lg-4 col-md-6  " />
                    </div>
                </div>

                {/* Page counter section */}
                <Counter />

                {/* Page Customer review seciton */}
                <CustomerReviews />

            </Base>
        </>
    )
}

export default Gallery