import React from 'react'

// Custom CSS import
import './homeSliders.css'

const HomeSliders = () => {
    return (
        <>
            <div className="home-sliders">
                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" data-bs-interval="2000">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="./images/slides-images/slide-3.png" className="d-block w-100" alt="loading..." />
                            <div className="carousel-caption text-end d-md-block">
                                <h2>Make your look beautiful</h2>
                                <p>Style is a way to say who you are without having to speak.</p>   
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src="./images/slides-images/slide-2.png" className="d-block w-100" alt="loading..." />
                            <div className="carousel-caption  d-md-block">
                                <h2>Your Beauty is Our Passion</h2>
                                <p>Style is a reflection of your attitude and your personality.</p>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src="./images/slides-images/slide-4.png" className="d-block w-100" alt="loading..." />
                            <div className="carousel-caption text-start d-md-block">
                                <h2>Hair care is our specialty</h2>
                                <p>We're happy to help you with whatever your hair needs are, from cuts to styles to colors.</p>
                            </div>
                        </div>
                        <div className="carousel-item ">
                            <img src="./images/slides-images/slide-1.png" className="d-block w-100" alt="loading..." />
                            <div className="carousel-caption text-start d-md-block">
                                <h2>Feel like a Man</h2>
                                <p>Our services are all about making you feel like the best version of yourself.</p>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </>
    )
}

export default HomeSliders