import React from 'react'

// Custom CSS import
import './contactPage.css'

// Custom Components import
import Base from '../base/Base'
import ContactForm from '../helper/ContactForm'
import Counter from '../counter/Counter'
import CustomerReviews from '../customer-reviews/CustomerReviews'

const ContactPage = () => {
    return (
        <>
            <Base title='Contact Us' imageLocation='./images/contact-us/contact-banner.png' >
                <div className="container contact-us-page-container mt-5">

                    {/*actual form input fields  */}
                    <ContactForm />

                    {/* business location address */}
                    <div className="address p-3 text-white">
                        <h2 className="text-center">ADDRESS</h2>
                        <div className="complete address">
                            <h6 className="text-uppercase mb-4 font-weight-bold">Contact</h6>
                            <p style={{color: "#D9DDDC"}}><i className="fas fa-home mr-3"></i> Plot No, 20A, Chunabhatti Kolar Rd, next to ICICI Bank, opposite Manoria Hospital, Deepak Society, Chuna Bhatti, Bhopal, Madhya Pradesh 462016</p>
                            <p style={{color: "#D9DDDC"}}><i className="fas fa-envelope mr-3"></i> contact@matrixsalons.com</p>
                            <p style={{color: "#D9DDDC"}}><i className="fas fa-phone mr-3"></i>  <a style={{textDecoration: "none"}} className='text-white' href='tel:+919009011223' > <span className="fa-li"></span><span className="ms-2">+91-9009011223</span></a></p>
                            <p style={{color: "#D9DDDC"}}><i className="fas fa-phone mr-3"></i>  <a style={{textDecoration: "none"}} className='text-white' href='tel:+919584584102' > <span className="fa-li"></span><span className="ms-2">+91-9584584102</span></a></p>
                        </div>
                    </div>
                </div>

                {/* business location address */}
                <div className="google-location">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.0860932058217!2d77.44372791497112!3d23.203532884861684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x80073961cb98cc9e!2sMatrix%20Unisex%20Salon!5e0!3m2!1sen!2sin!4v1647938584096!5m2!1sen!2sin"
                        allowFullScreen="" loading="lazy" className='google-map container' title='google-map-matrix unisex salon'>
                    </iframe>
                </div>

                {/* customer reviews */}
                <Counter />

                {/* customer reviews */}
                <CustomerReviews />

            </Base>
        </>
    )
}

export default ContactPage