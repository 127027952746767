import React from 'react'
import { Link } from 'react-router-dom'

// Custom CSS import
// import './treatments.css'

// Custom components import
import Base from '../base/Base'
import Counter from '../counter/Counter'
import CustomerReviews from '../customer-reviews/CustomerReviews'

const Treatments = () => {
    return (
        <>
            <Base title='Treatments' imageLocation='./images/about-page/about-banner.png' >
                <div className="hair-care-container mt-5">
                    {/* left side contents of thepage */}
                    <div className="left-side-contents ">
                        <div className="heade-image">
                            <img className='bottom-page-galery-image' src="./images/hair-care/hair-style.jpg" alt="loading" />
                        </div>
                        <p>
                            There are many variations of passages of Lorem Ipsum available but the majority have suffered that is alteration in that some form by injected humour or randomised words which don’t slightly dumm believable. If you are Lorem Ipsum you need to be sure there isn’t anything embarrassing. There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in that some form by injected humour or randomised words.
                        </p>

                        <p>
                            If you are Lorem Ipsum you need to be sure there isn’t anything embarrassing. There are many that variations of passages of Lorem Ipsum available but the majority have suffered alteration in that is some form by art injected words which don’t look even slightly believable.
                        </p>

                        <p>
                            If you are Lorem the Ipsum you need to be sure there isn’t anything embarrassing. There are many is variations of passages of Lorem Ipsum available but the majority have suffered alteration in that some form by injected humour or randomised words which don’t look even slightly believable. If at you to be sure there isn’t anything embarrassing.
                        </p>

                        <ul className="bullet-items">
                            <li>There are many variations of passages of Lorem Ipsum available.</li>
                            <li>There are many variations of passages of Lorem Ipsum available.</li>
                            <li>There are many variations of passages of Lorem Ipsum available.</li>
                            <li>There are many variations of passages of Lorem Ipsum available.</li>
                            <li>There are many variations of passages of Lorem Ipsum available.</li>
                        </ul>
                        <div className="page-bottom-gallery">
                            <div className="left-side-image">
                                <img className='bottom-page-galery-image' src="./images/hair-care/hair-cut-1.jpg" alt="loading" style={{ borderRadius: "1rem", border: "1px solid", borderColor: "white" }} />
                            </div>
                            <div className="right-side-image">
                                <img className='bottom-page-galery-image' src="./images/hair-care/hair-cut-2.jpg" alt="loading" style={{ borderRadius: "1rem", border: "1px solid", borderColor: "white" }} />
                            </div>
                        </div>
                    </div>

                    {/* right side contents of the page */}
                    <div className="right-side-contents">
                        <div className="category">
                            <h2 className='text-center text-white' >Category</h2>
                            <ul>
                                <li className='side-bar-list-item'><Link to="/styling"> Styling</Link></li>
                                <li className='side-bar-list-item'><Link to="/hair-color"> Hair Color</Link></li>
                                <li className='side-bar-list-item'><Link to="/skin"> Skin-Care</Link></li>
                                <li className='side-bar-list-item'><Link to="/bridal"> Bridal</Link></li>
                                <li className='side-bar-list-item'><Link to="/treatments"> Treatments</Link></li>
                                <li className='side-bar-list-item'><Link to="/contact"> Contact Us</Link></li>
                                <li className='side-bar-list-item'><Link to="/contact"> Book Appointment</Link></li>
                            </ul>
                        </div>
                    </div>

                </div>
                {/* page section counter */}
                <Counter />

                {/* page section Customer Reviews */}
                <CustomerReviews />
            </Base>
        </>
    )
}

export default Treatments