import React from 'react'
import { Link } from 'react-router-dom'

// Custom CSS import
import './ourServices.css'

const OurServices = () => {
    return (
        <>
            <div className="container-fluid our-services-container text-center py-5" style={{ backgroundColor: "#0c0d0f" }}>
                <div className="heading-contents container">
                    <h1 className="page-heding" style={{ color: "#D9DDDC" }}>Our Services</h1>
                    <hr className='our-service-hr' style={{ opacity: "1", color: "#ffbb33", maxWidth: "150px", marginBottom: "3rem" }} />
                    <p className='page-heding-description' >We have been satisfying our clients with our premium salon services for many years now. And we are still as excited and passionate about what we do as when we started out. With the widest range of services in our salon, we cater to all your needs, whether you want a new look for your hair or skin, or a complete makeover for your wedding day. Matrix Unisex Salon has been providing premium hair and beauty services to Bhopal since many years. We are the best salon in Bhopal near you. Come check us out!</p>
                </div>

                {/* first row of service items */}
                <div className="container service-items">
                    <div className="individual-service-item-1">
                        <div className="individual-item text-center p-5 m-1">
                            <img src="./images/home-page/service-section/hair-cut.png" alt="loading..." />
                            <h2 className="service-heading" style={{ color: "#BEBDB8" }}>Hair Care</h2>
                            <p >We've got you covered — when it comes to getting your hair done, we have an award-winning stylist who can give you that just-stepped-out-of-the-salon look in no time!</p>
                            <Link className="btn btn-outline-warning my-2" to="/hair-care">Know More</Link>
                        </div>
                    </div>

                    <div className="individual-service-item-1">
                        <div className="individual-item text-center p-5 m-1">
                            <img src="./images/home-page/service-section/hair-color.png" alt="loading..." style={{ color: 'white' }} />
                            <h2 className="service-heading" style={{ color: "#BEBDB8" }}>Hair Color</h2>
                            <p >We know what trends are hottest, no matter what season it is. We understand different colors suit on your skin tones and hair types, we have a range of colors to choose from.</p>
                            <Link className="btn btn-outline-warning my-2" to="/hair-care">Know More</Link>
                        </div>
                    </div>

                    <div className="individual-service-item-1">
                        <div className="individual-item text-center p-5 m-1">
                            <img src="./images/home-page/service-section/hair-style.png" alt="loading..." />
                            <h2 className="service-heading" style={{ color: "#BEBDB8" }}>Styling</h2>
                            <p >With the widest range of services available in our salon, we cater to all your needs, whether you want a new look for your hair or skin, or a complete makeover for your wedding day.</p>
                            <Link className="btn btn-outline-warning my-2" to="/hair-care">Know More</Link>
                        </div>
                    </div>
                </div>

                {/* Second row of service items */}
                <div className="container service-items">
                    <div className="individual-service-item-1">
                        <div className="individual-item text-center p-5 m-1">
                            <img src="./images/home-page/service-section/skin-care.png" alt="loading..." />
                            <h2 className="service-heading" style={{ color: "#BEBDB8" }}>Skin-care</h2>
                            <p >Matrix Unisex Salon is the perfect place to indulge in your skin care needs. Join the salon and experience a new level of beauty. you'll always look best with us</p>
                            <Link className="btn btn-outline-warning my-2" to="/skin-care">Know More</Link>
                        </div>
                    </div>

                    <div className="individual-service-item-1">
                        <div className="individual-item text-center p-5 m-1">
                            <img src="./images/home-page/service-section/bridal.png" alt="loading..." />
                            <h2 className="service-heading" style={{ color: "#BEBDB8" }}>Bridal</h2>
                            <p >Matrix Unisex Salon Hair and Beauty has been a trusted name in the beauty industry for over a decade. We're committed to high class services and our work has been appreciated by many</p>
                            <Link className="btn btn-outline-warning my-2" to="/bridal">Know More</Link>
                        </div>
                    </div>

                    <div className="individual-service-item-1">
                        <div className="individual-item text-center p-5 m-1">
                            <img src="./images/home-page/service-section/skin-treatments.png" alt="loading..." />
                            <h2 className="service-heading" style={{ color: "#BEBDB8" }}>Treatments</h2>
                            <p >Whether it's hair or skin treatments, our expert technicians have you covered from head to toe. Our skin care treatments will leave your skin feeling soft, smooth, and radiant! </p>
                            <Link className="btn btn-outline-warning my-2" to="/body-care">Know More</Link>
                        </div>
                    </div>
                </div>

                {/* old one */}
                {/* <div className="container service-items">
                    <div className="individual-item col-lg-4 text-center p-5 m-1">
                        <img src="./images/home-page/service-section/hair-cut.png" alt="loading..." />
                        <h2 className="service-heading" style={{ color: "#BEBDB8" }}>Hair Care</h2>
                        <p >We've got you covered — when it comes to getting your hair done, we have an award-winning stylist who can give you that just-stepped-out-of-the-salon look in no time!</p>
                        <Link className="btn btn-outline-warning my-2" to="/hair-care">Know More</Link>
                    </div>
                    <div className="individual-item col-lg-4 text-center p-5 m-1">
                        <img src="./images/home-page/service-section/hair-color.png" alt="loading..." style={{ color: 'white' }} />
                        <h2 className="service-heading" style={{ color: "#BEBDB8" }}>Hair Color</h2>
                        <p >We know what trends are hottest, no matter what season it is. We understand different colors suit on your skin tones and hair types, we have a range of colors to choose from.</p>
                        <Link className="btn btn-outline-warning my-2" to="/hair-color">Know More</Link>
                    </div>
                    <div className="individual-item col-lg-4 text-center p-5 m-1">
                        <img src="./images/home-page/service-section/hair-style.png" alt="loading..." />
                        <h2 className="service-heading" style={{ color: "#BEBDB8" }}>Styling</h2>
                        <p >With the widest range of services available in our salon, we cater to all your needs, whether you want a new look for your hair or skin, or a complete makeover for your wedding day.</p>
                        <Link className="btn btn-outline-warning my-2" to="/hair-styling">Know More</Link>
                    </div>
                </div> */}

                {/* second row of service items */}
                {/* <div className="container service-items">
                    <div className="individual-item col-lg-4 text-center p-5 m-1">
                        <img src="./images/home-page/service-section/skin-care.png" alt="loading..." />
                        <h2 className="service-heading" style={{ color: "#BEBDB8" }}>Skin-care</h2>
                        <p >Matrix Unisex Salon is the perfect place to indulge in your skin care needs. Join the salon and experience a new level of beauty. you'll always look your best with us</p>
                        <Link className="btn btn-outline-warning my-2" to="/skin">Know More</Link>
                    </div>
                    <div className="individual-item col-lg-4 text-center p-5 m-1">
                        <img src="./images/home-page/service-section/bridal.png" alt="loading..." />
                        <h2 className="service-heading" style={{ color: "#BEBDB8" }}>Bridal</h2>
                        <p >Matrix Unisex Salon Hair and Beauty has been a trusted name in the beauty industry for over a decade. We're committed to high class services and our work has been appreciated by many</p>
                        <Link className="btn btn-outline-warning my-2" to="/bridal">Know More</Link>
                    </div>
                    <div className="individual-item col-lg-4 text-center p-5 m-1">
                        <img src="./images/home-page/service-section/skin-treatments.png" alt="loading..." />
                        <h2 className="service-heading" style={{ color: "#BEBDB8" }}>Treatments</h2>
                        <p >Whether it's hair or skin treatments, our expert technicians have you covered from head to toe. Our skin care treatments will leave your skin feeling soft, smooth, and radiant! </p>
                        <Link className="btn btn-outline-warning my-2" to="/treatments">Know More</Link>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default OurServices