import React from 'react'
import { Link } from 'react-router-dom'

// Custom CSS import
// import './treatments.css'

// Custom components import
import Base from '../base/Base'
import Counter from '../counter/Counter'
import CustomerReviews from '../customer-reviews/CustomerReviews'

const BodyCare = () => {
    return (
        <>
            <Base title='Body Care' imageLocation='./images/body-care/body-care-banner.jpg' >
                <div className="hair-care-container mt-5">
                    {/* left side contents of thepage */}
                    <div className="left-side-contents ">
                        <div className="heade-image">
                            <img className='bottom-page-galery-image' src="./images/body-care/waxing-main.jpg" alt="loading" />
                        </div>
                        <p>
                            Get your hair and body waxed, it will feel great to exfoliate away the dead skin that could be around your face or in your armpits. D-tan polishing removes unwanted dark spots &amp; uneven skin tones.  Waxing is the removal of some or all hair from the body, while tanning is the application of a solution to your skin.
                        </p>

                        <p>
                            There is a reason why we are one of the most popular salons in town: our team of talented professionals strives to provide only the best and latest beauty treatments. You will always leave feeling relaxed and like new. Come in for a scalp massage or pedicure today !
                        </p>

                        <p>
                            Most people want to get rid of tan as soon as possible. We understand that feeling, which is why we developed a unique treatment that will remove unwanted tan from your skin in just one treatment! We use our patented formula to make sure that you leave this beauty salon with a beautiful and radiant complexion.
                        </p>

                        <ul className="bullet-items">
                            <li>There are many variations Waxing Services Available in Our Salon.</li>
                            <li>There are many variations of Pedicure and Manicure Available in Our Salon.</li>
                            <li>Body Waxing Available in Our Salon.</li>
                            <li>Body Polishing Available in Our Salon.</li>
                            <li>Body Massage Available in Our Salon.</li>
                        </ul>
                        <div className="page-bottom-gallery">
                            <div className="left-side-image">
                                <img className='bottom-page-galery-image' src="./images/body-care/waxing.jpg" alt="loading" style={{ borderRadius: "1rem", border: "1px solid", borderColor: "white" }} />
                            </div>
                            <div className="right-side-image">
                                <img className='bottom-page-galery-image' src="./images/body-care/pedicure.jpg" alt="loading" style={{ borderRadius: "1rem", border: "1px solid", borderColor: "white" }} />
                            </div>
                        </div>
                    </div>

                    {/* right side contents of the page */}
                    <div className="right-side-contents">
                        <div className="category">
                            <h2 className='text-center text-white' >Waxing</h2>
                            <ul>
                                <li className='side-bar-list-item'><Link to="#"> Honey wax</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Rica wax</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Brazilian wax</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> D-tan wax</Link></li>
                            </ul>
                        </div>
                        <div className="category">
                            <h2 className='text-center text-white' >Foot &amp; Palm</h2>
                            <ul>
                                <li className='side-bar-list-item'><Link to="#"> Pedicure</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Manicure</Link></li>
                            </ul>
                        </div>
                        <div className="category">
                            <h2 className='text-center text-white' >Body</h2>
                            <ul>
                                <li className='side-bar-list-item'><Link to="#"> Body wax</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Body D-tan</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Body polishing</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Body massage</Link></li>
                            </ul>
                        </div>
                    </div>

                </div>
                {/* page section counter */}
                <Counter />

                {/* page section Customer Reviews */}
                <CustomerReviews />
            </Base>
        </>
    )
}

export default BodyCare;