import React from 'react'

// Custom CSS import
import './homePage.css'

// Custom components import
import HomeSliders from './home-sliders/HomeSliders'
import WelcomeSection from './welcome-page/WelcomeSection'
import Footer from '../footer/Footer'
import OurServices from './our-services/OurServices'
import DiscoverOurWoks from './discover-our-works/DiscoverOurWoks'
import CustomerReviews from '../customer-reviews/CustomerReviews'
import Counter from '../counter/Counter'
import Contact from '../contact/Contact'

const HomePage = () => {
    return (
        <>

            {/* home page slides */}
            <HomeSliders />

            {/* home page Welcome section */}
            <WelcomeSection />

            {/* home page Services section */}
            <OurServices />

            {/* home page Services section */}
            <DiscoverOurWoks />

            {/* home page Customer Reviews section */}
            <CustomerReviews />

            {/* home page Customer Reviews section */}
            <Counter />

            {/* home page Customer Reviews section */}
            <Contact />

            {/* home Footer section */}
            <Footer />
        </>
    )
}

export default HomePage;