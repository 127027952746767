import React from 'react'

// Custom CSS import
import './salonLocation.css'

// Custom Components import
import Base from '../base/Base'
import ContactForm from '../helper/ContactForm'
import CustomerReviews from '../customer-reviews/CustomerReviews'

const SalonLocation = () => {
    return (
        <>
            <Base title='Our Salon Locations' imageLocation='./images/find-a-salon/salon-work-banner.jpg' >
                {/* Kolar Road address inforamtion */}
                <h1 className="page-heading text-center mt-5" style={{color: "#D9DDDC"}}>Matrix Unisex Salon, Kolar Road</h1>
                <hr className='our-service-hr' style={{ opacity: "1", color: "#ffbb33", maxWidth: "150px", marginBottom: "3rem" }} />

                <div className="container location-information mt-5">

                    {/*Kolar Road Google map  */}
                    <div className="google-location">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14668.64643859722!2d77.4167013!3d23.2007803!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xec9d169ca410d182!2sMatrix%20unisex%20salon.ME%20Salon!5e0!3m2!1sen!2sin!4v1648493016440!5m2!1sen!2sin" allowFullScreen="" loading="lazy" className='google-map container' title='google-map-matrix'>
                        </iframe>
                    </div>

                    {/* business location google map link */}
                    <div className="address p-3 ">
                      
                        <div className="complete address" >
                            <h6 className="text-uppercase mb-4 font-weight-bold">Contact</h6>
                            <p style={{color: "#D9DDDC"}}><i className="fas fa-home mr-3"></i> Plot No, 20A, Chunabhatti Kolar Rd, next to ICICI Bank, opposite Manoria Hospital, Deepak Society, Chuna Bhatti, Bhopal, Madhya Pradesh 462016</p>
                            <p style={{color: "#D9DDDC"}}><i className="fas fa-envelope mr-3"></i> contact@matrixsalons.com</p>
                            <p style={{color: "#D9DDDC"}}><i className="fas fa-phone mr-3"></i>  <a style={{textDecoration: "none"}} className='text-white' href='tel:+919009011223' > <span className="fa-li"></span><span className="ms-2">+91-9009011223</span></a></p>
                    
                        </div>
                    </div>
                </div>

                {/* Houshangabad branch address and information */}
                <h1 className="page-heading text-center mt-5" style={{color: "#D9DDDC"}}>Matrix Unisex Salon, Hoshangabad Road</h1>
                <hr className='our-service-hr' style={{ opacity: "1", color: "#ffbb33", maxWidth: "150px", marginBottom: "3rem" }} />

                <div className="container location-information mt-5">
                    {/*Business location google map link  */}
                    <div className="google-location">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14668.344371161931!2d77.4459166!3d23.2035329!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x80073961cb98cc9e!2sMatrix%20Unisex%20Salon!5e0!3m2!1sen!2sin!4v1648492667643!5m2!1sen!2sin"    allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className='google-map container' title='google-map-hosangabad'></iframe>
                    </div>

                    {/* business location address */}
                    <div className="address p-3 ">
                       
                        <div className="complete address">
                            <h6 className="text-uppercase mb-4 font-weight-bold">Contact</h6>
                            <p style={{color: "#D9DDDC"}}><i className="fas fa-home mr-3"></i> Plot No C, 20, Hoshangabad Rd, opposite Barkatullah University, Mohalla Kotwali Vidisha, Narayan Nagar, Bhopal, Madhya Pradesh 462026</p>
                            <p style={{color: "#D9DDDC"}}><i className="fas fa-envelope mr-3"></i> contact@matrixsalons.com</p>
                            <p style={{color: "#D9DDDC"}}><i className="fas fa-phone mr-3"></i>  <a style={{textDecoration: "none"}} className='text-white' href='tel:+919584584102' > <span className="fa-li"></span><span className="ms-2">+91-9584584102</span></a></p>
                            <p style={{color: "#D9DDDC"}}><i className="fas fa-phone mr-3"></i>  <a style={{textDecoration: "none"}} className='text-white' href='tel:+917554949224' > <span className="fa-li"></span><span className="ms-2">+ 91 755 494 9224</span></a></p>
                           
                        </div>
                    </div>
                </div>

                {/*contact form  */}
                <div className="contact-form mt-5">
                    <ContactForm />
                </div>

                {/* Customer Review section */}
                <CustomerReviews />

            </Base>
        </>
    )
}

export default SalonLocation