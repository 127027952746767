import React from 'react'

// Custom CSS import
import './discoverOurWoks.css'

const DiscoverOurWoks = () => {
    return (
        <>
            <div className=" container discover-our-works-container mt-5">

                <div className="row discover-our-works-contents">
                    <div className="col-lg-3 image-frame gap-xs-1">
                        <img src="/images/about-page/female-hair-cut.jpg" alt="loading..." />
                    </div>
                    <div className="col-lg-3 image-frame">
                        <img src="/images/about-page/male-hair-care.jpg" alt="loading..." />
                    </div>
                    <div className="col-lg-6 mt-sm-5">
                        <h2 className="section-heading" style={{ color: "#D9DDDC" }}>Discover Our Works</h2>
                        <hr style={{ opacity: "1", color: "#ffbb33", maxWidth: "150px", marginBottom: "3rem" }} />
                        <p>We've got the widest range of salon services for men and women. No matter what you're looking for, we've got it all - from haircuts and colouring to facials and spa treatments.</p>
                        <p>Helping keep your skin clean and healthy is our priority at Milan Salon. With a range of treatments including facials, peels and more, you'll find the perfect solution to any skin issues you might have. Find out more about our skin care services today!</p>
                        <a href="/portfolio" className="btn btn-outline-warning my-4">View All Works</a>
                    </div>
                </div>

                {/* some more images */}
                <div className="service-showcase row mt-xl-3">
                    <div className="col-lg-3 showcase-image-frame ">
                        <img src="./images/home-page/our-works/hiar-color.jpeg" alt="" className="showcase-image" />
                    </div>
                    <div className="col-lg-3 showcase-image-frame ">
                        <img src="./images/home-page/our-works/image-4.jpg" alt="" className="showcase-image" />
                    </div>
                    <div className="col-lg-3 showcase-image-frame ">
                        <img src="./images/home-page/our-works/female-hair-style.jpg" alt="" className="showcase-image" />
                    </div>
                    <div className="col-lg-3 showcase-image-frame ">
                        <img src="./images/home-page/our-works/image-3.jpg" alt="" className="showcase-image" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default DiscoverOurWoks