import { API } from "../../backend";

// Contact form API calls
export const contactPageEnquery = (enqueryData) => {
    return fetch(`${API}/contact`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(enqueryData)
    })
        .then(response => {
            return response.json();
        })
        .catch(error => console.log(error));
}