import React from 'react'
import { Link } from 'react-router-dom'

// Custom CSS import
import './skinCare.css'

// Custom Components import
import Base from '../base/Base'
import Counter from '../counter/Counter'
import CustomerReviews from '../customer-reviews/CustomerReviews'


const SkinCare = () => {
    return (
        <>
            <Base title='Skin-Care' imageLocation='./images/skin-care/skin-care-banner.png' >
                <div className="skin-care-container mt-5">
                    {/* left side contents of thepage */}
                    <div className="left-side-contents ">
                        <div className="heade-image">
                            <img className='bottom-page-galery-image' src="./images/skin-care/skin-care-main.jpg" alt="loading" />
                        </div>
                        <p>
                            If you are looking for beauty treatment services, Skin Care is available in our salon. The services are all-inclusive and consist of facials, massages and other skin care procedures that target specific areas of the body to make them more radiant. We offer facials, body wraps, massages, manicures, pedicures and more in a relaxing environment.
                        </p>

                        <p>
                            Our services include treatments for every skin type and concern to help you achieve the smooth, healthy look you want. We take pride in our quality care and make sure that each client is treated with personalized attention.
                        </p>

                        <p>
                        You feel good about yourself when you're wearing clean, beautiful-smelling clothes, but your skin is an important part of that equation. With over 15+ years of experience, our truly holistic approach will deliver your most desired results—and leave you feeling better about yourself every day..
                        </p>

                        <ul className="bullet-items">
                            <li>All Types of Bleach Available in our salon.</li>
                            <li>All Types of Facial Available in Our Salon.</li>
                            <li>Alll Types of Cleanup Available in Our Salon.</li>
                            <li>Sensi Acne Treatments Availble in Our Salon.</li>
                            <li>Blanch Treatmetns Available in Our Salon.</li>
                        </ul>
                        <div className="page-bottom-gallery">
                            <div className="left-side-image">
                                <img className='bottom-page-galery-image' src="./images/skin-care/facial.jpg" alt="loading" style={{ borderRadius: "1rem", border: "1px solid", borderColor: "white" }} />
                            </div>
                            <div className="right-side-image">
                                <img className='bottom-page-galery-image' src="./images/skin-care/skin-treatment.jpg" alt="loading" style={{ borderRadius: "1rem", border: "1px solid", borderColor: "white" }} />
                            </div>
                        </div>
                    </div>

                    {/* right side contents of the page */}
                    <div className="right-side-contents">
                        <div className="category">
                            <h2 className='text-center text-white' >Bleach</h2>
                            <ul>
                                <li className='side-bar-list-item'><Link to="#"> Regular bleach</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Gold bleach</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> D-tan</Link></li>
                            </ul>
                        </div>
                        <div className="category">
                            <h2 className='text-center text-white' >Clean-Up</h2>
                            <ul>
                                <li className='side-bar-list-item'><Link to="#"> Hydra clean-up</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Oil control clean-up </Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Brightning clean-up</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Rediant clean-up</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Snow white clean-up</Link></li>
                            </ul>
                        </div>
                        <div className="category">
                            <h2 className='text-center text-white' >Facials</h2>
                            <ul>
                                <li className='side-bar-list-item'><Link to="#"> Glowvite facial</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Tanclear facial</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Oxyblast facial</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Sensiglow facial</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Vitalift facial</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> O3+ Whitning facial</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> O3+ Seawead facial</Link></li>
                            </ul>
                        </div>
                        <div className="category">
                            <h2 className='text-center text-white' >Skin Treatment</h2>
                            <ul>
                                <li className='side-bar-list-item'><Link to="#"> Dermalight treatment</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Sensi Enzime treatment</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Sensi Acne treatment</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Clari Enzime treatment</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> O2c2 treatment</Link></li>
                                <li className='side-bar-list-item'><Link to="#"> Blanch treatment</Link></li>
                            </ul>
                        </div>
                    </div>

                </div>
                {/* page section counter */}
                <Counter />

                {/* page section Customer Reviews */}
                <CustomerReviews />
            </Base>
        </>
    )
}

export default SkinCare